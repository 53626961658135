
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/cdp-week-2023",
      function () {
        return require("private-next-pages/cdp-week-2023/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/cdp-week-2023"])
      });
    }
  