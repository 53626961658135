import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next'
import { useMemo } from 'react'
import { QueryClient, dehydrate, type DehydratedState } from '@tanstack/react-query'

import CdpWeek2023View from 'views/CdpWeek2023'

import { createCdpWeekSpeakerLandingPageQuery } from '@segment/api/routes/cdpWeek2023/queries'

import { StaticSiteBackendProvider, createStaticSiteBackend } from '@segment/contexts/staticSiteBackend'

import type { AppExternalPageProps } from '@segment/next-shared/Providers'

const CdpWeek2023: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  const staticSiteBackend = useMemo(() => createStaticSiteBackend(), [])

  return (
    <StaticSiteBackendProvider value={staticSiteBackend}>
      <CdpWeek2023View />
    </StaticSiteBackendProvider>
  )
}

export const getStaticProps: GetStaticProps<AppExternalPageProps> = async () => {
  const queryClient = new QueryClient()
  const backendClient = createStaticSiteBackend()

  await queryClient.prefetchQuery(...createCdpWeekSpeakerLandingPageQuery(backendClient, { speakersLimit: 3 }), {
    staleTime: Infinity,
  })

  const dangerousDehydratedState = dehydrate(queryClient)
  const safeDehydratedState: DehydratedState = JSON.parse(JSON.stringify(dangerousDehydratedState))
  const props: AppExternalPageProps = {
    dehydratedState: safeDehydratedState,
  }

  return {
    props,
  }
}

export default CdpWeek2023
