import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'next/link'
import type { AnimationItem } from 'lottie-web'

import Button from '@segment/matcha/components/Button'

import HeroBase, * as HeroRecipe from '@segment/matcha/recipes/Hero'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import useLottie from '@segment/utils/useLottie/useLottie'
import useIsInView from '@segment/utils/useInView'

import useDomainPath from '../../../utils/useDomainPath'

import jsonUrl from './assets/hero-animation.json.data'

const OVERLINE = `VIRTUAL SUMMIT: October 24–26, 2023`
const HEADING = `CDP Week: Fuel AI with trusted data for real-time impact`
const DESCRIPTION = `Catch up on insights from our 3rd annual CDP Week: AI Edition. During this free 3-day virtual summit, AI expert, Sinead Bovell and industry leaders from Allergan, GitHub, OneTrust, Accenture, and more shared how the latest advancements are helping companies win with trusted customer data.`

const button = {
  text: `Watch on-demand`,
}

const Hero: FC = () => {
  const theme = useTheme()
  const registerLink = useDomainPath(`register`)

  useEffect(() => {
    setAnimationData(fetch(jsonUrl))
  }, [])

  const [animationData, setAnimationData] = useState<Promise<Response>>()
  const [animation, containerRef] = useLottie<AnimationItem, HTMLDivElement>({
    options: {
      animationData,
      autoplay: true,
      loop: false,
    },
  })
  const isInView = useIsInView(containerRef)

  useEffect(() => {
    if (isInView && animation) {
      animation.playSegments([0, animation.totalFrames], true)
      animation.addEventListener(`enterFrame`, () => {
        if (animation.currentFrame >= 105) {
          animation.playSegments([105, animation.totalFrames], false)
        }
      })
    } else {
      animation?.stop()
    }
  }, [animation, isInView])

  return (
    <Wrapper>
      <StyledHero variant="2Col">
        <HeroRecipe.Copy>
          <CopyTextBase>
            <CopyText.Overline tokens={{ color: theme.palette.cdpWeek2023.saffron[100] }}>{OVERLINE}</CopyText.Overline>
            <CopyText.Heading variant="h1" tag="h1">
              {HEADING}
            </CopyText.Heading>
            <ButtonWrapper>
              <Link href={registerLink}>
                <Button variant="filled" size="large" color="primary">
                  {button.text}
                </Button>
              </Link>
            </ButtonWrapper>
            <CopyText.Description tag="p" variant="pLarge">
              {DESCRIPTION}
            </CopyText.Description>
          </CopyTextBase>
        </HeroRecipe.Copy>
        <HeroRecipe.Contents>
          <Animation ref={containerRef} />
        </HeroRecipe.Contents>
      </StyledHero>
    </Wrapper>
  )
}

export default Hero

const Animation = styled.div`
  width: 120%;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    width: 110%;
  }
`

const StyledHero = styled(HeroBase)`
  ${HeroRecipe.Wrapper} {
    gap: 100px;
    padding-top: 0;
    padding-bottom: 0;

    ${({ theme }) => theme.breakpoints.down(`md`)} {
      gap: 40px;
      grid-template-areas:
        'contents'
        'copy';
    }
  }
`

const Wrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  margin: 32px 0;
`
