import { useEffect, type FC, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import type { AnimationItem } from 'lottie-web'

import Button from '@segment/matcha/components/Button'
import { type ButtonVariant } from '@segment/matcha/components/Button/Button'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'
import useIsInView from '@segment/utils/useInView'
import useLottie from '@segment/utils/useLottie/useLottie'

import { usePageConfig } from '@segment/contexts/page'

import ListBase, * as List from '../../../components/List'

import useDomainPath from '../../../utils/useDomainPath'

import jsonUrl from './assets/cdp_week_lottie_icon.json.data'

const HEADING = `You’ll walk away with a plan for how to:`
const list = [
  `Leverage AI to unlock customer insights and deliver personalized experiences that drive sustainable growth`,
  `Build loyalty through multichannel customer journeys—while maintaining trust and privacy`,
  `Activate new features, like Predictions and Generative Journeys, to fuel precise, real-time customer engagements`,
]

type ButtonItem = {
  name: string
  href: string
  variant: ButtonVariant
}

const Plan: FC = () => {
  const pageConfig = usePageConfig()
  const [animationData, setAnimationData] = useState<Promise<Response>>()
  const registerLink = useDomainPath(`register`)
  const agendaLink = useDomainPath(`agenda`)

  useEffect(() => {
    setAnimationData(fetch(jsonUrl))
  }, [])

  const buttons: ButtonItem[] = useMemo(
    () => [
      {
        name: `Watch on-demand`,
        href: registerLink,
        variant: `filled`,
      },
      {
        name: `View the agenda`,
        href: agendaLink,
        variant: `outlined`,
      },
    ],
    [registerLink, agendaLink]
  )

  const [animation, containerRef] = useLottie<AnimationItem, HTMLDivElement>({
    options: {
      autoplay: false,
      animationData,
      loop: true,
    },
  })
  const isInView = useIsInView(containerRef)

  useEffect(() => {
    if (isInView && animation) {
      animation.playSegments([0, animation.totalFrames], true)
      animation.addEventListener(`enterFrame`, () => {
        if (animation.currentFrame >= 103) {
          animation.playSegments([104, animation.totalFrames], false)
        }
      })
    } else {
      animation?.stop()
    }
  }, [animation, isInView])

  return (
    <Wrapper>
      <StyledSection variant="2Col" reverse={true}>
        <Section.Contents>
          <Animation ref={containerRef} />
        </Section.Contents>
        <Section.Copy>
          <CopyTextBase>
            <CopyText.Heading tag="h2">{HEADING}</CopyText.Heading>
          </CopyTextBase>
          <ListWrapper>
            <ListBase>
              {list.map((item, key) => (
                <List.Item key={key}>{item}</List.Item>
              ))}
            </ListBase>
          </ListWrapper>
          <ButtonsWrapper>
            {buttons.map((button) => (
              <Button
                key={button.name}
                variant={button.variant}
                href={getAbsoluteUrl(button.href, pageConfig)}
                size="large"
                color="primary"
              >
                {button.name}
              </Button>
            ))}
          </ButtonsWrapper>
        </Section.Copy>
      </StyledSection>
    </Wrapper>
  )
}

export default Plan

const Wrapper = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${transparentize(0.76, theme.palette.neutrals.black[100])} 0%, ${transparentize(
      0.76,
      theme.palette.neutrals.black[100]
    )} 100%), ${theme.palette.cdpWeek2023.gray[100]}`};
`

const StyledSection = styled(SectionBase)`
  ${Section.Wrapper} {
    gap: 300px;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-bottom: 0;
      gap: 80px;
    }
  }
`

const Animation = styled.div`
  width: 120%;
`

const ListWrapper = styled.div`
  margin-top: 32px;
`
const ButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
`
